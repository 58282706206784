import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo, Suspense, useEffect, useState } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight2: '#FFFFFF',
      neutralLight1: '#F8F7F5',
      neutralLight0: '#EAE5DF',
      neutralDark3: '#797979',
      neutralDark2: '#13373B',
      neutralDark1: '#0D0D0D',
      primaryDark: '#1F5A61',
      primaryLight: '#B49C80',
      danger: '#D51000',
    },
  },
  fontFamily: {
    heading: "'the-seasons', sans-serif",
    smallHeading: "'kudryashev-d-contrast-sans', sans-serif",
    paragraph: "'halyard-display', sans-serif",
  },
}

export type Styles = typeof styles

export const styles = {
  label: css`
    color: ${theme.colors.variants.primaryLight};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 0.875rem;
    letter-spacing: 0.1em;
    line-height: 1.125rem;
    text-transform: uppercase;
  `,
  labelSmall: css`
    color: ${theme.colors.variants.neutralDark1};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 0.75rem;
    font-weight: 200;
    letter-spacing: 0.01em;
    line-height: 1.375rem;
  `,
  button: css`
    color: ${theme.colors.variants.neutralLight2};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 0.875rem;
    letter-spacing: 0.05em;
    line-height: 1.1875rem;
  `,
  title: {
    xl: css`
      color: ${theme.colors.variants.neutralLight2};
      font-family: ${theme.fontFamily.heading};
      font-size: 4.5rem;
      line-height: 5rem;
      letter-spacing: 0.01em;

      @media (max-width: 1199px) {
        font-size: 2rem;
        line-height: 2.25rem;
      }
    `,
    large: css`
      color: ${theme.colors.variants.neutralDark1};
      font-family: ${theme.fontFamily.heading};
      font-size: 3.75rem;
      line-height: 4.625rem;
      letter-spacing: 0.01em;

      @media (max-width: 1199px) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    `,
    medium: css`
      color: ${theme.colors.variants.neutralDark1};
      font-family: ${theme.fontFamily.heading};
      font-size: 2.5rem;
      line-height: 3.125rem;
      letter-spacing: 0.01em;

      @media (max-width: 1199px) {
        font-size: 1.375rem;
        line-height: 1.625rem;
      }
    `,
    small: css`
      color: ${theme.colors.variants.neutralLight2};
      font-family: ${theme.fontFamily.smallHeading};
      font-size: 2rem;
      line-height: 2.375rem;
      letter-spacing: 0.01em;
    `,
    xs: css`
      color: ${theme.colors.variants.neutralDark1};
      font-family: ${theme.fontFamily.smallHeading};
      font-size: 1.5rem;
      line-height: 1.75rem;
      letter-spacing: 0.01em;
    `,
  },
  description: css`
    color: ${theme.colors.variants.neutralDark1};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.375rem;
    letter-spacing: 0.01em;
  `,
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

interface Props {
  children: any
  fallback?: any
  pageContext: any
}

export const ThemeProvider = memo(function ThemeProvider({
  children,
  fallback,
}: Props) {
  const isBrowser = typeof window !== 'undefined'

  let smoothScroll = false
  if (isBrowser) smoothScroll = window.innerWidth > 1199 ? true : false

  const [isMounted, setMounted] = useState<boolean>(false)

  useEffect(() => {
    if (!isMounted) {
      setMounted(true)
    }
  }, [isBrowser])

  return (
    <EmotionThemeProvider theme={theme}>
        <Suspense fallback={fallback}>
        {!isMounted ? (
          fallback
        ) : (
          <>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>{children}</MediaContextProvider>
      </>
        )}
      </Suspense>
    </EmotionThemeProvider>
  )
})
